import {graphql} from 'gatsby';
import React from 'react';
import {getRoutes} from '../../shared/routes';
import Layout from '../../shared/Layout';
import SEO from '../../shared/SEO';
import css from './allNews.module.scss';
import Footer from '../../shared/Footer/Footer';
import AllNewsList from "./partials/AllNewsList/AllNewsList";
import {useTranslation} from "react-i18next";

/*
NOTE: If add field to frontmatter, add this field to content/news/template/index.md
Graphql with Gatsby needs at least one md file with fields getting here.
Template is used to copy and create any news.
 */
export const query = graphql`
  query($postIDs: [String]!) {
    allMarkdownRemark(
      filter: { id: { in: $postIDs } },
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            externalLink
            image {
              childImageSharp {
                fluid(maxWidth: 290, quality: 70) {
                  ...GatsbyImageSharpFluid,
                  originalName
                }
              }
            }
            imageDescription
            youtube
            copyright
          }
        }
      }
    }
    site {
      siteMetadata {
        newsMarkdownTemplateFile
      }
    }
  }
`;

const AllNews = props => {
  const { t } = useTranslation();
  const { index } = props.pageContext || {};
  const routes = getRoutes();
  const pageTitle = `${routes.news.pageTitle} | ${index}`;

  return (
    <Layout>
      <SEO title={pageTitle} description={'Digital transformation sharing'}/>

      <div className={css.wrapper}>

      <div className={css.HomeNews} id={routes.home.news}>
          <AllNewsList pageContext={props.pageContext} allNewsDetails={props.data} />
        </div>

        <div className={css.Footer}>
          <Footer/>
        </div>

      </div>
    </Layout>
  );
};

export default AllNews;
