import React from 'react';
import css from './newsPaginationButton.module.scss';

const NewsPaginationButton = props => {
    const {
        label = '',
        filled = false,
        disabled = false
    } = props;
    const filledClass = filled ? css.filled : '';
    const disabledClass = disabled ? css.disabled : '';

    return (
        <div className={[css.mainButton, filledClass, disabledClass].join(' ')}>
            {label}
        </div>
    );

};

export default NewsPaginationButton;
