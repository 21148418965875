import React from "react";
import css from './newsBasicTemplate.module.scss';
import Tags from "../Tags/Tags";
import {Link} from "gatsby";
import NewsDate from "../NewsDate/NewsDate";

const NewsBasicTemplate = props => {
  const {
    external = false,
    link = '',
    content,
    contentType,
    copyright,
    tags,
    title,
    date
  } = props;

  const renderTitle = () => {
    if (!link) {
      return title;
    }
    if (external) {
      return (<a href={link} target="_blank">{title}</a>);
    }
    return (<Link to={link}>{title}</Link>);
  };

  const isImage = (contentType === 'image');
  // const isYoutube = (contentType === 'youtube');

  return (
    <div className={css.wrapper}>
      <div className={css.graphicContent}>
        <div className={css.content}>{content}</div>
        <div className={css.overlay}>
          <Tags stringTags={tags} />
        </div>
        { isImage && copyright && <div className={css.copyright}>&copy; {copyright}</div> }
      </div>
      <div className={css.textContent}>
      <div className={css.date}><NewsDate date={date} /></div>

        <div className={css.title}>{renderTitle()}</div>
      </div>
    </div>
  )
};

export default NewsBasicTemplate;
